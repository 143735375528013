import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { navigate } from "@reach/router"
import SEO from "src/components/global/SEO.js"
import Cookies from "src/components/global/Cookies.js"
import { Link } from "gatsby"

import typographySizes from "src/assets/styles/typographySizes.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import noHangers from "src/components/global/fn/noHangers.js"
import BigText from "src/components/global/typography/BigText"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import BodyText from "src/components/global/typography/BodyText"

import ShortNewsSection from "src/components/global/ShortNewsSection.js"
// import NewBusinessSection from "src/components/global/NewBusinessSection.js"
import SliderChangeImg from "src/components/global/SliderChangeImgGit"
import Author_Date from "src/components/NewsPost/Author_Date.js"

import Wrapper from "src/components/global/Wrapper"
import slugify from "slugify"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import NewsLink from "src/components/home/NewsLink.js"
import MainBtn from "src/components/global/btn/MainBtn"

import { func } from "prop-types"

const Title = styled(BigText)`
  width: 75%;
  /* text-transform: lowercase; */
  /* ::first-letter {
    text-transform: uppercase;
  } */
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const TopWrapper = styled(Wrapper)`
  margin-bottom: 50px;
`
const Paragraph = styled(BodyText)`
  width: 50%;
  margin-left: 25%;
  white-space: pre-line;
  code {
    white-space: pre-line;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${typographySizes.l}px;
    margin-bottom: 20px;
  }
  .marginP {
    p {
      margin-bottom: 20px;
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 75%;
    margin-left: 25%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
  }
`

const Quote = styled.h4`
  margin: 100px 0;
  font-size: ${typographySizes.l}px;
  width: 50%;
  line-height: 1.2;
  margin-left: 16.66%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 75%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
  }
`

const ClaimIntroWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  flex-wrap: wrap;
`
const ClaimIntroClaim = styled.h4`
  width: calc(40% - 20px);
  font-size: ${typographySizes.l}px;
  line-height: 1.2;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-bottom: 100px;
  }
`

const ClaimIntroIntro = styled(BodyText)`
  width: 50%;
  margin-bottom: -20px;
  .marginP {
    column-count: 2;
    column-gap: 20px;
    p {
      margin-bottom: 30px;
    }
    @media (max-width: ${mediaQuery.desktop}) {
      column-count: 1;
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`

const ClaimIntroTopWrapper = styled(Wrapper)`
  margin-bottom: 50px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ClaimIntroAuthor = styled.div`
  width: 50%;
  margin-top: 150px;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 50%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 50px;
  }
`

const OnlyClaimWrapper = styled.div`
  width: 50%;
  margin-left: 25%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 75%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
  }
`

const OnlyClaimWrapperClaim = styled.h4`
  width: 100%;
  font-size: ${typographySizes.l}px;
  line-height: 1.2;
`

const OnlyClaimAuthor = styled.div`
  width: 75%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 50px;
  }
`

const goBack = () => {
  navigate(-1)
}
const BackLink = styled(Link)`
  font-size: ${typographySizes.s}px;
  text-decoration: none;
  cursor: pointer;
`

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const PolicyLayout = ({ data, pageContext }) => {
  return (
    <>
      <Navigation
        lang={pageContext.locale}
        langUrl={pageContext.langUrl}
        navBtnLink={data.strapiGlobal.Nav_btn_link}
        navBtnText={data.strapiGlobal.Nav_btn_text}
      />

      {data.strapiPrivacyPolicy.Content.map(document => {
        if (document.Paragraph !== null && document.Paragraph !== undefined) {
          return (
            <Wrapper>
              <Paragraph>
                <ReactMarkdown
                  className={"marginP"}
                  source={noHangers(document.Paragraph)}
                  linkTarget={"_blank"}
                />
              </Paragraph>
            </Wrapper>
          )
        }
        if (document.Quote !== null && document.Quote !== undefined) {
          return (
            <Wrapper>
              <Quote>{noHangers(document.Quote)}</Quote>
            </Wrapper>
          )
        }
        if (document.Slider !== null && document.Slider !== undefined) {
          return (
            <SliderChangeImg
              allFile={pageContext.allFiles.data.allFile}
              Gallery={document.Slider}
            />
          )
        }
      })}

      <Footer companyData={data.strapiContactPageN} />
    </>
  )
}
export const query = graphql`
  query PrivacyPolicy($locale: String!) {
    strapiGlobal {
      Nav_btn_link
      Nav_btn_text
    }
    strapiPrivacyPolicy(locale: { eq: $locale }) {
      Content
    }
    strapiContactPageN(locale: { eq: $locale }) {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
    }
  }
`

export default PolicyLayout
